<template>
  <div v-if="volume">
    <nav class="navbar" style="border-bottom: 1px solid #dbdbdb;">
      <div class="navbar-menu">
        <div class="navbar-start">
          <router-link class="navbar-item" :to="{ name: 'volume', params: {volumeId: volume.id} }">
            <fa-icon :icon="['fas', 'chevron-left']" />
            <span class="ml-2">目次へ</span>
          </router-link>
        </div>
        <div class="navbar-end">
          <user-nav-item />
        </div>
      </div>
    </nav>

    <div class="container is-fullhd mt-4 pb-4">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <div class="tabs is-centered">
              <ul>
                <li v-for="(label, key) in tabs" :key="key" :class="{ 'is-active': currentTab === key }">
                  <a @click="currentTab = key">{{ label }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button is-primary" @click="save(currentTab)">保存</button>
          </div>
        </div>
      </div>

      <div v-if="currentTab === 'categories'">
        <table class="table is-fullwidth data-table">
          <thead>
            <tr>
              <th style="width: 100px;">position</th>
              <th>title</th>
              <th>description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in categories" :key="c.id">
              <td>
                <b-input v-model.number="c.position" type="number" />
              </td>
              <td>
                <b-input v-model="c.title" />
              </td>
              <td>
                <b-input v-model="c.description" type="textarea" rows="3" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="currentTab === 'contents'">
        <table class="table is-fullwidth data-table">
          <thead>
            <tr>
              <th style="width: 100px;">position</th>
              <th style="width: 200px;">category</th>
              <th>title</th>
              <th style="width: 150px;">videoId</th>
              <th style="width: 110px;">accessCount</th>
              <th style="width: 110px;">likeCount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in contents" :key="c.id">
              <td>
                <b-input v-model.number="c.position" type="number" />
              </td>
              <td>{{ c.categoryRef.title }}</td>
              <td>
                <b-input v-model="c.title" />
              </td>
              <td>
                <b-input v-model="c.videoId" />
              </td>
              <td>
                <b-input v-model="c.accessCount" />
              </td>
              <td>
                <b-input v-model="c.likeCount" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import { db } from '@/main'
import UserNavItem from '@/components/UserNavItem'

export default {
  components: { UserNavItem },
  data() {
    return {
      tabs: { categories: "カテゴリ", contents: "コンテンツ" },
      currentTab: 'categories',
      volumeId: this.$route.params.volumeId,
      volume: null,
      categories: null,
      contents: null,
    }
  },
  firestore() {
    const volumeRef = db.collection('volumes').doc(this.volumeId)

    return {
      volume: volumeRef,
      categories: volumeRef.collection('categories').orderBy('position', 'asc'),
      contents: volumeRef.collection('contents').orderBy('position', 'asc'),
    }
  },
  methods: {
    save: function(collection) {
      this[collection].forEach(d => {
        this.$firestoreRefs.volume.collection(collection).doc(d.id).update(
          {
            position: d.position || '',
            title: d.title || '',
            videoId: d.videoId || '',
            accessCount: d.accessCount || 0,
            likeCount: d.likeCount || 0,
          }
        )
      })
      this.$toasted.show("保存しました")
    }
  }
}
</script>


<style lang="scss" scoped>
  ::v-deep .data-table {
    .input, .textarea {
      box-shadow: none;
      border: none;
      background-color: whitesmoke;

      &:hover, &:focus {
        background-color: #fffaeb;
      }
    }
  }
</style>
