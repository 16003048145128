<template>
  <div class="navbar-item has-dropdown is-hoverable" v-if="user && user.email">
    <a class="navbar-link" v-on:click.prevent>
      <fa-icon icon="user-circle" size="lg" />
    </a>

    <div class="navbar-dropdown is-right is-boxed">
      <div class="navbar-item has-text-grey">{{ user.email }}</div>
      <router-link class="navbar-item" :to="{ name: 'volume', params: {volumeId: volumeId} }" v-if="volumeId">目次</router-link>
      <router-link class="navbar-item" :to="{ name: 'survey', params: {volumeId: volumeId} }" v-if="volumeId">アンケート</router-link>
      <hr class="navbar-divider">
      <router-link class="navbar-item" :to="{ name: 'dashboard', params: {volumeId: volumeId} }" v-if="volumeId">ダッシュボード</router-link>
      <router-link class="navbar-item" :to="{ name: 'manage', params: {volumeId: volumeId} }" v-if="volumeId">コンテンツ管理</router-link>
      <hr class="navbar-divider">
      <a class="navbar-item" @click="singOut">ログアウト</a>
    </div>
  </div>
</template>


<script>
import { auth } from '@/main'

export default {
  data() {
    return {
      user: auth.currentUser,
      volumeId: this.$route.params.volumeId,
    }
  },
  methods: {
    singOut() {
      auth.signOut().then(() => {
        this.$router.push({ name: 'home' })
      })
    }
  },
}
</script>
